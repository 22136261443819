<script setup>
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    clockTime: {
        type: String,
        default: ''
    }
});

const interval = ref(null);
const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const intervals = ref({
    second: 1000,
    minute: 1000 * 60,
    hour: 1000 * 60 * 60,
    day: 1000 * 60 * 60 * 24
});

const date = computed(() => {
    return new Date(props.clockTime);
});

function updateDiffs() {
    let diff = Math.abs(Date.now() - date.value.getTime());
    days.value = Math.floor(diff / intervals.value.day);
    diff -= days.value * intervals.value.day;
    hours.value = Math.floor(diff / intervals.value.hour);
    diff -= hours.value * intervals.value.hour;
    minutes.value = Math.floor(diff / intervals.value.minute);
    diff -= minutes.value * intervals.value.minute;
    seconds.value = Math.floor(diff / intervals.value.second);
}

onMounted(() => {
    interval.value = setInterval(() => {
        updateDiffs();
    }, 1000);

    updateDiffs();
});
</script>

<template>
    <div class="flex space-x-1">
        <span v-if="days">{{ `${days} d` }}</span>
        <span v-if="hours">{{ `${hours} h` }}</span>
        <span v-if="minutes">{{ `${minutes} m` }}</span>
        <span v-if="seconds && !minutes">{{ `${seconds} s` }}</span>
    </div>
</template>

<style scoped lang="scss"></style>
