import { defineStore } from 'pinia';
import _ from 'lodash';
import dayjs from 'dayjs';
import { pushToHistory } from "@/composables/useTabHistory";

const defaultState = {
    activeTab: 'overview',
    activeSubTab: 'work-order',
    dirtyState: false,
    showSaveDialog: false,
    allFieldsValid: true,
    save: false,
    edit: false,
    filters: {
        tca: {
            search: '',
            projects: []
        },
        pmCheckout: {
            search: '',
            projectManager: []
        }
    },
    employees: {
        search: '',
        page: 1,
        filters: {
            status: 'active'
        }
    },
    schedule: {
        date: dayjs().format('YYYY-MM-DD')
    },
    projects: {
        allPopNotes: [],
    },
    navbar: {
        shouldCollapseNavigation: false,
        shouldCloseMobileNav: false
    }
};
export const useAppStore = defineStore('app', {
    state: () => ({ ...defaultState }),
    getters: {
        getCurrentTab: () => defaultState.activeTab,
        getCurrenSubtTab: () => defaultState.activeSubTab,
        getNavbarCollapseState: (state) =>
            state.navbar.shouldCollapseNavigation,
        getMobileNavState: (state) => state.navbar.shouldCloseMobileNav,
        getPopUpNote: (state) => state.projects.allPopNotes
    },
    actions: {
        reset(keys) {
            Object.assign(
                this,
                keys?.length ? _.pick(defaultState, keys) : defaultState // if no keys provided, reset all
            );
        },
        toggleShowSaveDialog() {
            this.showSaveDialog = !this.showSaveDialog;
        },
        toggleEdit() {
            this.edit = !this.edit;
        },
        toggleSave() {
            this.save = !this.save;
        },
        setCurrentTab(tab, useHistory = false) {
            this.activeTab = tab;

            if (useHistory) {
                pushToHistory(tab, 'tab', true);
            }
        },
        setCurrentSubTab(tab, useHistory = false) {
            this.activeSubTab = tab;

            if (useHistory) {
                pushToHistory(tab, 'subtab', true);
            }
        },
        handleToggleNav() {
            this.navbar.shouldCollapseNavigation =
                !this.navbar.shouldCollapseNavigation;
        },
        handleToggleMobileNav() {
            this.navbar.shouldCloseMobileNav =
                !this.navbar.shouldCloseMobileNav;
        },
        setPopupNotes(notes) {
            this.projects.allPopNotes = notes;
        }
    }
});
