/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* Define the icons */
import {
    faChevronDown,
    faChevronRight,
    faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(faChevronDown, faChevronRight, faCalendarAlt);
