<script setup>
import { useClockStore } from '@/stores/ClockStore';
import { Link } from '@inertiajs/vue3';
import ClockOut from '@/Components/Clock/Modules/ClockOut.vue';
import SwitchClock from '@/Components/Clock/Modules/SwitchClock.vue';
import dayjs from 'dayjs';

const clock = useClockStore();
</script>

<template>
<div v-if="clock.workOrder">
    <div
        class="border rounded-md grid grid-cols-2 py-6 justify-items-center mb-2"
    >
        <div class="col-span-2 pb-4">
            <h1 class="font-semibold text-lg">
                {{ clock.workOrder.job_name }}
            </h1>
        </div>
        <div class="self-center">
            {{ `WO# ${clock.workOrder.id}` }}
        </div>
        <div class="self-center">
            <span
                :style="`background-color: ${clock.workOrder.service.service_color.hex_code}`"
                class="bg-gray-600 inline-flex items-center min-w-[95px] justify-center px-2 py-1 text-xs font-medium text-white rounded-full"
            >
                {{ clock.workOrder.service.abbreviation }}
            </span>
        </div>
        <div class="text-center col-span-2 text-lg mt-2">
            {{ clock.workOrder.date }}
        </div>
        <Link
            v-if="
                clock.clockDirection === 'Switch' &&
                    !clock.incorrectEntry.denied
            "
            :href="`/work-order/${clock.switchWorkOrder}`"
            class="col-span-2 inline-flex w-11/12 justify-center rounded-md bg-white px-3 py-3 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 mt-2 sm:mt-4"
            @click="clock.showClockModal = false"
        >
            {{ `View WO# ${clock.switchWorkOrder}` }}
        </Link>
    </div>
    <div
        v-if="clock.clockDirection === 'In'"
        class="mt-5 sm:mt-6 grid sm:grid-flow-row-dense grid-cols-2 gap-x-2"
    >
        <button
            v-if="
                !clock.isWorkOrderCompleted &&
                    (dayjs(clock.workOrder.date).isToday() ||
                        dayjs(clock.workOrder.date).isTomorrow())
            "
            :disabled="clock.showClockLoading"
            class="inline-flex w-full justify-center rounded-md bg-green-600 disabled:bg-gray-400 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
            type="button"
            @click="
                clock.saveTimeEntry(clock.clockType, clock.workOrder.id)
            "
        >
            <span
                v-if="clock.showClockLoading"
                class="flex items-center"
            >
                <span class="pr-2">Saving</span>
                <span
                    class="border-white-300 h-4 w-4 animate-spin rounded-full border-2 border-t-ha-300"
                >
                    <!-- ... -->
                </span>
            </span>
            <span v-else>
                Clock
                {{ clock.clockDirection }}
            </span>
        </button>
        <Link
            v-if="clock.workOrder"
            :class="[
                (dayjs(clock.workOrder.date).isToday() ||
                    dayjs(clock.workOrder.date).isTomorrow()) &&
                    !clock.isWorkOrderCompleted
                    ? ''
                    : 'col-span-2'
            ]"
            :href="`/work-order/${clock.workOrder.id}`"
            class="inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
            type="button"
            @click="clock.showClockModal = false"
        >
            {{ `View WO# ${clock.workOrder.id}` }}
        </Link>
    </div>
    <template v-if="clock.clockDirection === 'Out'">
        <ClockOut />
    </template>
    <template v-if="clock.clockDirection === 'Switch'">
        <SwitchClock />
    </template>
</div>
</template>

<style lang="scss" scoped></style>
