<template>
    <svg
        viewBox="0 0 23 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.2405 0.90332C6.90405 0.90332 2.81077 4.96602 2.33091 9.79366H0.5L3.11638 12.2781L5.972 9.79366H4.12034C4.57807 5.68387 7.91907 2.21046 12.3304 2.21046C17.0487 2.21046 20.889 5.88624 20.889 10.4033C20.889 14.9204 17.0487 18.5975 12.3304 18.5975C9.98645 18.5975 7.86099 17.69 6.31495 16.2237L5.17961 17.275C7.07528 18.9588 9.60612 19.9008 12.2405 19.9033C17.8992 19.9033 22.5 15.6408 22.5 10.4033C22.5 5.16585 17.8992 0.90332 12.2405 0.90332Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.4805 4.65039V11.027L16.1657 14.8367L17.1657 13.8867L12.8218 9.75422V4.65039H11.4805Z"
            fill="currentColor"
        />
    </svg>
</template>
