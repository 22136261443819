<script setup>
import { computed, ref, watch } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import axios from "axios";

const show = ref(true);
const message = computed(
    () => usePage().props.currentAnnouncements?.content || ""
);
const title = computed(() => usePage().props.currentAnnouncements?.title || "");

const acknowledgeSave = () => {
    axios
        .post(`/announcements/${usePage().props.currentAnnouncements.id}`)
        .then((response) => {
            // reload page
            router.reload();
        })
        .catch((error) => {
            console.log(error);
        });
};

watch(message, async () => {
    show.value = true;
});
</script>

<template>
<div>
    <div
        v-if="show"
        class="static top-0 z-100 min-w-full -mt-14 pt-14 bg-yellow-50 px-4 pb-4"
    >
        <div class="flex pt-4">
            <div class="flex-shrink-0">
                <svg
                    aria-hidden="true"
                    class="h-5 w-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        clip-rule="evenodd"
                        d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                        fill-rule="evenodd"
                    />
                </svg>
            </div>
            <div class="ml-3">
                <h3 class="text-xl font-medium text-yellow-800">
                    {{ title }}
                </h3>
                <div class="mt-2 text-lg text-black" v-html="message"></div>
                <div class="mt-8">
                    <div class="-mx-2 -my-1.5 flex">
                        <button
                            class="ml-3 rounded-md bg-yellow-200 border border-yellow-800 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                            type="button"
                            @click="acknowledgeSave"
                        >
                            Acknowledge
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
