<script setup>
import { useClockStore } from "@/stores/ClockStore";
import dayjs from "dayjs";
import IncorrectEntry from "@/Components/Clock/Modules/IncorrectEntry.vue";
import { onMounted } from "vue";

const clock = useClockStore();

onMounted(() => {
    clock.timeClock.out = dayjs().toISOString();
});
</script>

<template>
    <div>
        <div
            class="border rounded-md grid grid-cols-2 gap-x-2 md:gap-x-0 pt-4 justify-items-center"
        >
            <div
                v-if="clock.timeClock.task"
                class="col-span-2 pb-4 text-center w-full"
            >
                <h1 class="font-normal text-lg">Clocked In WO</h1>
                <div class="flex justify-center pb-4">
                    <h1 class="text-center font-semibold text-lg">
                        {{ clock.timeClock.task.job_name }}
                    </h1>
                </div>
                <div class="grid grid-cols-2 w-full">
                    <div class="self-center">
                        {{ `WO# ${clock.timeClock.task.id}` }}
                    </div>
                    <div class="self-center">
                        <span
                            :style="`background-color: ${clock.timeClock.task.service.service_color.hex_code}`"
                            class="bg-gray-600 inline-flex items-center min-w-[95px] justify-center px-2 py-1 text-xs font-medium text-white rounded-full"
                        >
                            {{ clock.timeClock.task.service.abbreviation }}
                        </span>
                    </div>
                </div>

                <div class="text-center col-span-2 text-lg mt-2">
                    {{ clock.timeClock.task.date }}
                </div>
                <h3 class="block text-sm font-light">
                    #{{ clock.timeClock.id }}
                </h3>
            </div>
            <div v-else class="col-span-2 pb-4 text-center w-full">
                <h1 class="font-normal text-lg">Clocked In</h1>
                <div class="flex justify-center pb-4">
                    <h1 class="text-center font-semibold text-lg">
                        {{ clock.timeClock.type.display_name }}
                    </h1>
                </div>
                <h3 class="block text-sm font-light">
                    #{{ clock.timeClock.id }}
                </h3>
            </div>
            <div class="col-span-2 md:col-span-1 pt-0 relative">
                <span class="block text-center uppercase font-light pr-1">
                    IN
                </span>
                <span class="text-center text-xl block">
                    {{ dayjs(clock.timeClock.in).format("LT z") }}
                </span>
                <span class="text-center block text-sm">
                    {{ dayjs(clock.timeClock.in).format("l") }}
                </span>
            </div>
            <div class="col-span-2 md:col-span-1 pt-0 relative">
                <span class="block text-center uppercase font-light pr-1">
                    OUT
                </span>
                <span class="text-center text-xl block">
                    {{ dayjs(clock.timeClock.out).format("LT z") }}
                </span>
                <span class="text-center block text-sm">
                    {{ dayjs(clock.timeClock.out).format("l") }}
                </span>
            </div>
        </div>
        <div v-if="clock.incorrectEntry.denied">
            <IncorrectEntry />
        </div>
        <div
            v-else
            class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
        >
            <template v-if="!clock.clockOut && clock.switchWorkOrder">
                <button
                    v-if="
                        (clock.clockType === 'cones' &&
                            (dayjs(clock.workOrder.date).isToday() ||
                                dayjs(clock.workOrder.date).isYesterday())) ||
                        (clock.clockType === 'task' &&
                            !clock.isWorkOrderCompleted &&
                            (dayjs(clock.workOrder.date).isToday() ||
                                dayjs(clock.workOrder.date).isTomorrow()))
                    "
                    class="sm:col-span-2 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-1"
                    type="button"
                    @click="clock.handleClockOut('Switch')"
                >
                    Switch WO
                </button>
                <button
                    v-else
                    class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-1 col-span-2"
                    type="button"
                    @click="clock.handleClockOut('Out')"
                >
                    Clock Out
                </button>
            </template>
            <template v-else>
                <button
                    :disabled="clock.showClockLoading"
                    class="inline-flex w-full justify-center rounded-md bg-red-600 disabled:bg-gray-400 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2"
                    type="button"
                    @click="clock.toggleEditClock()"
                >
                    Edit
                </button>
                <button
                    :disabled="clock.showClockLoading"
                    class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-3 text-md font-semibold text-white shadow-sm ring-1 ring-inset ring-green-600 disabled:bg-gray-400 hover:bg-green-500 sm:col-start-1 mt-2 sm:mt-0"
                    @click="clock.updateTimeEntry()"
                >
                    <span
                        v-if="clock.showClockLoading"
                        class="flex items-center"
                    >
                        <span class="pr-2">Saving & Switching</span>
                        <span
                            class="border-white-300 h-4 w-4 animate-spin rounded-full border-2 border-t-ha-300"
                        >
                            <!-- ... -->
                        </span>
                    </span>
                    <span v-else>Correct</span>
                </button>
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
