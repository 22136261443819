<script setup>
import ClockedInTimer from '@/Components/Clock/ClockedInTimer.vue';
import { useClockStore } from '@/stores/ClockStore';
import { onMounted } from 'vue';

const clock = useClockStore();

onMounted(() => {
    clock.getAuthUserData();
});
</script>

<template>
    <div>
        <!-- Clock Out -->
        <button
            v-if="clock.isUserClockedIn"
            class="flex justify-center items-center space-x-2 h-8 px-3 mr-2 text-xs font-semibold rounded-lg bg-success text-white"
        >
            <ClockedInTimer
                :clock-time="clock.user.clocked_entry.in"
                @click="clock.toggleClockOut(clock.user.clocked_entry)"
            />
        </button>
        <!-- Clock In -->
        <button
            v-else
            class="flex justify-center items-center space-x-2 h-8 px-3 mr-2 text-xs font-semibold rounded-lg bg-success text-white"
            @click="clock.toggleClock('choice')"
        >
            Clock In
        </button>
    </div>
</template>

<style scoped lang="scss"></style>
