<template>
    <svg
        width="20"
        height="25"
        viewBox="0 0 20 25"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.0002 0H1.99978V2.08308H18.0002V0ZM1.99978 25H18.0002V22.9169H1.99978V25ZM18.0002 4.16692H1.99978C1.46988 4.16851 0.962115 4.38849 0.587415 4.7788C0.212714 5.16911 0.00153152 5.69802 0 6.25V18.75C0.00153152 19.302 0.212714 19.8309 0.587415 20.2212C0.962115 20.6115 1.46988 20.8315 1.99978 20.8331H18.0002C18.5301 20.8315 19.0379 20.6115 19.4126 20.2212C19.7873 19.8309 19.9985 19.302 20 18.75V6.25C19.9985 5.69802 19.7873 5.16911 19.4126 4.7788C19.0379 4.38849 18.5301 4.16851 18.0002 4.16692ZM10.0004 7.02927C10.4453 7.02927 10.8801 7.16669 11.2501 7.42415C11.62 7.68161 11.9083 8.04755 12.0785 8.47569C12.2488 8.90384 12.2933 9.37495 12.2065 9.82947C12.1197 10.284 11.9055 10.7015 11.5909 11.0292C11.2763 11.3569 10.8755 11.58 10.4392 11.6704C10.0029 11.7608 9.55058 11.7144 9.13956 11.5371C8.72853 11.3597 8.37723 11.0594 8.13006 10.6741C7.88289 10.2888 7.75097 9.83577 7.75097 9.37235C7.75212 8.7513 7.98948 8.15602 8.41107 7.71686C8.83267 7.27771 9.40414 7.03046 10.0004 7.02927ZM15.0005 17.7062H5.00018V16.1461C5.00018 14.4077 8.33001 13.5423 10.0004 13.5423C11.6707 13.5423 15.0005 14.407 15.0005 16.1461V17.7062Z"
            fill="currentColor"
        />
    </svg>
</template>
