<script setup>
import "animate.css";
import ApplicationLogo from "../Components/ApplicationLogo.vue";
import CollapseIcon from "../Components/Icons/CollapseIcon.vue";
import Banner from "../Components/Banner.vue";
import TopBar from "../Components/Nav/TopBar.vue";

import NavBar from "../Components/Nav/NavBar.vue";

import { useAppStore } from "@/stores/AppStore";

import GlobalSavePrompt from "@/Components/Save/GlobalSavePrompt.vue";
import TimeClockContainer from "@/Components/Clock/TimeClockContainer.vue";
import AnnouncementBanner from "@/Components/AnnouncementBanner.vue";

const app = useAppStore();

defineProps({
    title: {
        type: String,
        default: "",
    },
});

</script>

<template>
<div class="min-h-screen bg-white">
    <GlobalSavePrompt/>
    <TimeClockContainer />
    <Banner />
    <div
        :class="`${
            app.getNavbarCollapseState
                ? 'lg:ml-[4.685rem] '
                : 'lg:ml-52 xl:ml-60'
        }`"
        class="min-h-screen bg-white"
    >
        <!-- Header -->
        <header
            :class="`${
                app.getNavbarCollapseState ? 'w-[4.685rem]' : 'xl:w-60 w-52'
            }`"
            class="fixed inset-y-0 left-0 z-50 contents overflow-y-auto bg-holbrook-dark-blue border-r border-gray-900/10 px-0 dark:border-white/10 lg:block"
        >
            <!-- xl:w-60 -->
            <div>
                <div
                    v-if="!app.getNavbarCollapseState"
                    class="hidden lg:flex h-16 py-2 items-center justify-center bg-white"
                >
                    <a aria-label="Home" href="/">
                        <ApplicationLogo class="h-7 lg:h-12 w-auto" />
                    </a>
                </div>
                <button
                    class="lg:flex w-full items-center justify-center p-4 text-ha-300"
                    @click="app.handleToggleNav"
                >
                    <CollapseIcon class="h-6 w-6" />
                </button>
                <TopBar />
                <NavBar />
            </div>
        </header>
        <div
            class="relative flex flex-col h-screen md:px-0 mt-0.5 lg:pt-14"
        >
            <main class="flex-grow bg-lightGray-100 px-2 sm:px-6 lg:px-0">
                <AnnouncementBanner
                    v-if="$page.props.currentAnnouncements"
                />
                <div class="z-120">
                    <!-- <GlobalSavePrompt /> -->
                </div>
                <!-- <main class="flex-grow bg-lightGray-100 px-2 md:px-0   px-4 sm:px-6 lg:px-0"> -->
                <article class="lg:mx-8">
                    <slot />
                </article>
            </main>
            <footer class="w-full py-4 bg-lightGray-100">
                <div
                    class="mx-auto max-w-2xl lg:max-w-full border-t border-zinc-900/5 pt-8 dark:border-white/5"
                >
                    <div
                        class="mx-auto max-w-5xl flex flex-col items-center justify-between gap-5 sm:flex-row"
                    >
                        <p class="text-xs text-zinc-600 dark:text-zinc-400">
                            © Copyright {{ new Date().getFullYear() }}. All
                            rights reserved.
                        </p>
                        <div class="flex gap-4">
                            <p
                                class="text-xs text-zinc-600 dark:text-zinc-400"
                            >
                                ProfitOps Build 2.1.3
                            </p>
                            <a class="group" href="/#">
                                <svg
                                    aria-hidden="true"
                                    class="h-5 w-5 fill-zinc-700 transition group-hover:fill-zinc-900 dark:group-hover:fill-zinc-500"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        clip-rule="evenodd"
                                        d="M10 1.667c-4.605 0-8.334 3.823-8.334 8.544 0 3.78 2.385 6.974 5.698 8.106.417.075.573-.182.573-.406 0-.203-.011-.875-.011-1.592-2.093.397-2.635-.522-2.802-1.002-.094-.246-.5-1.005-.854-1.207-.291-.16-.708-.556-.01-.567.656-.01 1.124.62 1.281.876.75 1.292 1.948.93 2.427.705.073-.555.291-.93.531-1.143-1.854-.213-3.791-.95-3.791-4.218 0-.929.322-1.698.854-2.296-.083-.214-.375-1.09.083-2.265 0 0 .698-.224 2.292.876a7.576 7.576 0 0 1 2.083-.288c.709 0 1.417.096 2.084.288 1.593-1.11 2.291-.875 2.291-.875.459 1.174.167 2.05.084 2.263.53.599.854 1.357.854 2.297 0 3.278-1.948 4.005-3.802 4.219.302.266.563.78.563 1.58 0 1.143-.011 2.061-.011 2.35 0 .224.156.491.573.405a8.365 8.365 0 0 0 4.11-3.116 8.707 8.707 0 0 0 1.567-4.99c0-4.721-3.73-8.545-8.334-8.545Z"
                                        fill-rule="evenodd"
                                    ></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</div>
</template>

<style></style>
