<script setup>
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useClockStore } from "@/stores/ClockStore";
import { onMounted } from "vue";
import ChoiceMenu from "@/Components/Clock/Modules/ChoiceMenu.vue";
import TaskClock from "@/Components/Clock/Modules/TaskClock.vue";
import ConesClock from "@/Components/Clock/Modules/ConesClock.vue";
import TravelClock from "@/Components/Clock/Modules/TravelClock.vue";
import ClockOut from "@/Components/Clock/Modules/ClockOut.vue";

const clock = useClockStore();

onMounted(() => {
    clock.fetchTimeClockTypes();
});
</script>

<template>
<TransitionRoot :show="clock.showClockModal" as="template">
    <Dialog as="div" class="relative z-50" @close="clock.closeClock">
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
            <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div
                class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <DialogPanel
                        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-lg sm:p-2"
                    >
                        <div v-if="clock.clockType === 'cleaning'">
                            <DialogTitle
                                :as="`div`"
                                class="p-4 sm:px-6 sm:py-4 border-b border-gray-200/30 flex justify-between"
                            >
                                <h3>
                                    Work Order Clock
                                    {{ clock.clockDirection }}
                                </h3>
                                <button
                                    v-if="!clock.showClockLoading"
                                    class="text-lg leading-6 font-medium text-gray-900"
                                    @click="clock.closeClock()"
                                >
                                    <XMarkIcon class="h-6 w-6" />
                                </button>
                            </DialogTitle>
                            <TaskClock />
                            <!--                            <DialogTitle-->
                            <!--                                :as="`div`"-->
                            <!--                                class="p-4 sm:px-6 sm:py-4 border-b border-gray-200/30 flex justify-between"-->
                            <!--                            >-->
                            <!--                                <h3>-->
                            <!--                                    Cleaning Clock-->
                            <!--                                    {{ clock.clockDirection }}-->
                            <!--                                </h3>-->
                            <!--                                <button-->
                            <!--                                    v-if="!clock.showClockLoading"-->
                            <!--                                    class="text-lg leading-6 font-medium text-gray-900"-->
                            <!--                                    @click="clock.closeClock()"-->
                            <!--                                >-->
                            <!--                                    <XMarkIcon class="h-6 w-6" />-->
                            <!--                                </button>-->
                            <!--                            </DialogTitle>-->
                            <!--                            <CleaningClock />-->
                            <!--                            <div-->
                            <!--                                class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"-->
                            <!--                            >-->
                            <!--                                <button-->
                            <!--                                    class="inline-flex w-full justify-center rounded-md bg-[#ce1212] px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="clock.showClockModal = false"-->
                            <!--                                >-->
                            <!--                                    Cleaning Clock-->
                            <!--                                    {{ clock.clockDirection }}-->
                            <!--                                </button>-->
                            <!--                                <button-->
                            <!--                                    v-if="clock.clockData"-->
                            <!--                                    class="inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"-->
                            <!--                                    type="button"-->
                            <!--                                    @click="clock.showClockModal = false"-->
                            <!--                                >-->
                            <!--                                    {{-->
                            <!--                                        `View Cleaning ${clock.clockData.id}`-->
                            <!--                                    }}-->
                            <!--                                </button>-->
                            <!--                            </div>-->
                        </div>
                        <div v-else-if="clock.clockType === 'task'">
                            <DialogTitle
                                :as="`div`"
                                class="p-4 sm:px-6 sm:py-4 border-b border-gray-200/30 flex justify-between"
                            >
                                <h3>
                                    Work Order Clock
                                    {{ clock.clockDirection }}
                                </h3>
                                <button
                                    v-if="!clock.showClockLoading"
                                    class="text-lg leading-6 font-medium text-gray-900"
                                    @click="clock.closeClock()"
                                >
                                    <XMarkIcon class="h-6 w-6" />
                                </button>
                            </DialogTitle>
                            <TaskClock />
                        </div>
                        <div v-else-if="clock.clockType === 'cones'">
                            <DialogTitle
                                :as="`div`"
                                class="p-4 sm:px-6 sm:py-4 border-b border-gray-200/30 flex justify-between"
                            >
                                <h3>
                                    Cones & Carpet Clock
                                    {{ clock.clockDirection }}
                                </h3>
                                <button
                                    v-if="!clock.showClockLoading"
                                    class="text-lg leading-6 font-medium text-gray-900"
                                    @click="clock.closeClock()"
                                >
                                    <XMarkIcon class="h-6 w-6" />
                                </button>
                            </DialogTitle>
                            <ConesClock />
                        </div>
                        <div v-else-if="clock.clockType === 'mob'">
                            <DialogTitle
                                :as="`div`"
                                class="p-4 sm:px-6 sm:py-4 border-b border-gray-200/30 flex justify-between"
                            >
                                <h3>
                                    MOB Clock
                                    {{ clock.clockDirection }}
                                </h3>
                                <button
                                    v-if="!clock.showClockLoading"
                                    class="text-lg leading-6 font-medium text-gray-900"
                                    @click="clock.closeClock()"
                                >
                                    <XMarkIcon class="h-6 w-6" />
                                </button>
                            </DialogTitle>
                            <TravelClock />
                        </div>
                        <div v-else>
                            <template v-if="clock.clockDirection === 'In'">
                                <DialogTitle
                                    :as="`div`"
                                    class="p-4 sm:px-6 sm:py-4 border-b border-gray-200/30 flex justify-between"
                                >
                                    <h3>Choose Clock Type</h3>
                                    <button
                                        v-if="!clock.showClockLoading"
                                        class="text-lg leading-6 font-medium text-gray-900"
                                        @click="clock.closeClock()"
                                    >
                                        <XMarkIcon class="h-6 w-6" />
                                    </button>
                                </DialogTitle>
                                <ChoiceMenu />
                            </template>
                            <template v-else>
                                <DialogTitle
                                    :as="`div`"
                                    class="p-4 sm:px-6 sm:py-4 border-b border-gray-200/30 flex justify-between"
                                >
                                    <h3 class="capitalize">
                                        {{
                                            clock.timeClock.type
                                                .display_name ?? "Time"
                                        }}
                                        Clock
                                    </h3>
                                    <button
                                        v-if="!clock.showClockLoading"
                                        class="text-lg leading-6 font-medium text-gray-900"
                                        @click="clock.closeClock()"
                                    >
                                        <XMarkIcon class="h-6 w-6" />
                                    </button>
                                </DialogTitle>
                                <ClockOut />
                            </template>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </div>
    </Dialog>
</TransitionRoot>
</template>

<style lang="scss" scoped></style>
