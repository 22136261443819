<script setup>
import { useClockStore } from '@/stores/ClockStore';
import dayjs from 'dayjs';
import IncorrectEntry from '@/Components/Clock/Modules/IncorrectEntry.vue';
import { Link } from '@inertiajs/vue3';
import { onMounted, ref } from 'vue';

const clock = useClockStore();

onMounted(() => {
    clock.timeClock.out = dayjs().toISOString();
});

const clockOut = ref(false);
</script>

<template>
<div>
    <div
        class="grid grid-cols-2 gap-x-2 md:gap-x-0 pt-4 pb-2 justify-items-center"
    >
        <div class="col-span-2 pb-4 text-center">
            <h1 class="font-semibold text-lg">Clock Out</h1>
            <h3 class="block text-sm font-light">
                #{{ clock.timeClock.id }}
            </h3>
            <p class="uppercase">
                {{
                    dayjs(clock.timeClock.out)
                        .diff(dayjs(clock.timeClock.in), 'hours', true)
                        .toFixed(2)
                }}
                <span class="text-xs font-light">hours</span>
            </p>
        </div>
        <div class="col-span-2 md:col-span-1 pt-0 relative">
            <span
                class="text-xs block text-center uppercase font-light pr-1"
            >
                Current IN
            </span>
            <span class="text-center text-xl block">
                {{ dayjs(clock.timeClock.in).format('LT z') }}
            </span>
            <span class="text-center block text-sm">
                {{ dayjs(clock.timeClock.in).format('l') }}
            </span>
        </div>
        <div class="col-span-2 md:col-span-1 pt-0 relative">
            <span
                class="text-xs block text-center uppercase font-light pr-1"
            >
                Current OUT
            </span>
            <span class="text-center text-xl block">
                {{ dayjs(clock.timeClock.out).format('LT z') }}
            </span>
            <span class="text-center block text-sm">
                {{ dayjs(clock.timeClock.out).format('l') }}
            </span>
        </div>
    </div>
    <div v-if="clock.incorrectEntry.denied">
        <IncorrectEntry />
    </div>
    <div
        v-else
        class="mt-5 sm:mt-6 grid sm:grid-flow-row-dense grid-cols-2 gap-x-2"
    >
        <template v-if="!clockOut && clock.switchWorkOrder">
            <Link
                :href="`/work-order/${clock.switchWorkOrder}`"
                class="inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                @click="clock.showClockModal = false"
            >
                {{ `View ${clock.switchWorkOrder}` }}
            </Link>
            <button
                class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                type="button"
                @click="clockOut = true"
            >
                Clock Out
            </button>
        </template>
        <template v-else>
            <button
                :disabled="clock.showClockLoading"
                class="inline-flex w-full justify-center rounded-md bg-green-600 disabled:bg-gray-400 px-3 py-3 text-md font-semibold text-white shadow-sm ring-1 ring-inset ring-green-600 hover:bg-green-500 sm:col-start-1"
                @click="clock.updateTimeEntry()"
            >
                <span
                    v-if="clock.showClockLoading"
                    class="flex items-center"
                >
                    <span class="pr-2">Saving</span>
                    <span
                        class="border-white-300 h-4 w-4 animate-spin rounded-full border-2 border-t-ha-300"
                    >
                        <!-- ... -->
                    </span>
                </span>
                <span v-else>Correct</span>
            </button>
            <button
                :disabled="clock.showClockLoading"
                class="inline-flex w-full justify-center rounded-md bg-red-600 disabled:bg-gray-400 px-3 py-3 text-md font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                type="button"
                @click="clock.toggleEditClock()"
            >
                Edit
            </button>
        </template>
    </div>
</div>
</template>

<style lang="scss" scoped></style>
