<script setup>
import { useClockStore } from '@/stores/ClockStore';
import { onMounted } from 'vue';

const clock = useClockStore();

onMounted(() => {
    clock.fetchTimeClockTypes();
});
</script>

<template>
    <div class="flex">
        <div class="relative inline-block grow">
            <ul
                id="options"
                class="max-h-96 scroll-py-3 overflow-y-auto p-0"
                role="listbox"
            >
                <!-- Active: "bg-gray-100" -->
                <li
                    v-for="(clockType, index) in clock.timeClockTypes"
                    id="option-1"
                    class="group flex cursor-default select-none rounded-xl p-3 hover:bg-gray-100"
                    role="option"
                    tabindex="-1"
                >
                    <div class="ml-4 flex-auto">
                        <p class="text-sm font-medium text-gray-700">
                            {{ clockType.display_name }}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ clockType.description }}
                        </p>
                    </div>
                    <div>
                        <button
                            class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ha-400 focus:ring-offset-2"
                            @click="clock.toggleClock(clockType.name)"
                        >
                            clock in
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
