<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <defs>
        <!-- <style>
          .cls-2 {
            fill: #303030;
          }
        </style> -->
      </defs>
      <g id="Group_142" data-name="Group 142" transform="translate(-279 -501)">
        <rect id="Rectangle_175" data-name="Rectangle 175" class="cls-1" width="30" height="30" rx="4" transform="translate(279 501)"/>
        <g id="travel" transform="translate(281.964 503.964)">
          <path id="Path_56" data-name="Path 56" style="fill:#303030;" class="cls-2" d="M3.511,0A3.514,3.514,0,0,0,0,3.511c0,1.767,2.78,5.845,3.1,6.3a.5.5,0,0,0,.826,0c.317-.459,3.1-4.536,3.1-6.3A3.514,3.514,0,0,0,3.511,0Zm0,5.015a1.5,1.5,0,1,1,1.5-1.5A1.505,1.505,0,0,1,3.511,5.015Z"/>
          <g id="Group_125" data-name="Group 125" transform="translate(5.016 9.026)">
            <path id="Path_57" data-name="Path 57" style="fill:#303030" class="cls-2" d="M17.538,24.045a10.583,10.583,0,0,1-2.084-.189.5.5,0,0,1,.2-.984,9.566,9.566,0,0,0,1.885.17.5.5,0,1,1,0,1Zm-3.833-.889a.5.5,0,0,1-.27-.079,4.492,4.492,0,0,1-1.518-1.608.5.5,0,1,1,.873-.5,3.5,3.5,0,0,0,1.186,1.258.5.5,0,0,1-.271.925Zm-2.014-3.315a.5.5,0,0,1-.49-.4,17.455,17.455,0,0,1-.264-2.052.5.5,0,0,1,1-.079,16.438,16.438,0,0,0,.247,1.932.5.5,0,0,1-.492.6Zm-.4-4a.5.5,0,0,1-.5-.459,13.738,13.738,0,0,0-.27-1.919.5.5,0,1,1,.978-.223,14.79,14.79,0,0,1,.291,2.056.5.5,0,0,1-.457.542A.3.3,0,0,1,11.293,15.845Zm-1.011-3.838a.5.5,0,0,1-.421-.23A3.326,3.326,0,0,0,8.6,10.621a.5.5,0,1,1,.47-.886,4.366,4.366,0,0,1,1.635,1.5.5.5,0,0,1-.148.693A.494.494,0,0,1,10.282,12.007ZM6.939,10.1a.453.453,0,0,1-.072-.005A12,12,0,0,0,5.5,10h0A.5.5,0,0,1,5,9.5.5.5,0,0,1,5.514,9a10.586,10.586,0,0,1,1.5.1.5.5,0,0,1-.071,1Z" transform="translate(-5.001 -8.999)"/>
          </g>
          <path id="Path_58" data-name="Path 58" style="fill:#303030" class="cls-2" d="M19.514,10A4.519,4.519,0,0,0,15,14.514c0,2.286,3.664,8.573,4.081,9.282a.5.5,0,0,0,.865,0c.418-.709,4.081-7,4.081-9.282A4.519,4.519,0,0,0,19.514,10Zm0,7.021a2.508,2.508,0,1,1,2.508-2.508A2.51,2.51,0,0,1,19.514,17.021Z" transform="translate(0.045 0.03)"/>
        </g>
      </g>
    </svg>
</template>
