<template>
  <svg fill="currentColor" viewBox="0 0 84 74" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 40.1 (33804) - http://www.bohemiancoding.com/sketch -->
    <title>task</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="task" fill="currentColor">
        <g id="Group">
          <path
              d="M39.3,73.7 L83.3,73.7 L67.8,46.7 C63,49 60.2,53.1 57.6,56.6 L39.9,46.3 L31.9,16.3 C31.5,14.5 29.8,12.7 28,11.9 L28,11.9 C27.4,11.6 26.7,11.4 26.1,11.4 L12.4,11.4 C10.3,11.4 8.9,12.5 7.8,14.3 L1.5,24.6 C0.5,26.6 1.2,29.2 2.6,29.8 L11,34.7 L10.7,35.1 C9.8,36.9 10.6,39.2 12,40.1 L29.8,50 L29.8,69.2 C29.8,71.2 31.4,72.9 33.5,72.9 L33.6,72.9 C34.7,72.9 35.6,72.5 36.3,71.7 C36.9,71.1 37.3,70.2 37.3,69.3 L37.3,49.7 L54.9,59.8 C50.9,63.5 46.5,61.7 43.3,65.2 C41.4,67.5 40,70.5 39.3,73.7 L39.3,73.7 Z M13.2,30.9 L7.5,27.6 L13.3,17.9 L20.7,17.9 L13.2,30.9 L13.2,30.9 Z M23.6,37 L28.5,28.5 L32.2,41.9 L23.6,37 L23.6,37 Z"
              id="Shape"
          ></path>
          <path
              d="M36.3,15 C40.2,15 42.5,11.6 42.5,7.5 C42.5,3.8 39.6,1.2 36.2,0.6 L36.2,0.6 C35.8,0.5 35.3,0.5 34.9,0.5 C31,0.5 29.9,4 29.9,8.3 C30,12.5 32.4,15 36.3,15 L36.3,15 Z"
              id="Shape"
          ></path>
          <path
              d="M18.8,45.6 L10.8,41.1 L11.8,46.7 C11.9,47.9 12.7,50.3 11.8,51.5 L1.3,66.5 C0.2,68.1 0.8,70.6 2.2,71.7 C4.2,73.3 6.9,71.6 7.4,70.7 L18.7,54.5 C19,53.9 19.9,51.4 19.6,49.2 C19.6,49.3 19.2,45.8 18.8,45.6 L18.8,45.6 Z"
              id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "ManpowerIcon"
}
</script>

<style scoped>

</style>