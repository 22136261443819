<template>
    <svg
        viewBox="0 0 17 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.49847 10.4298C10.6263 10.4298 12.3739 9.74367 12.5002 8.8151C12.1965 7.96529 11.8418 7.01916 11.5125 6.11099C11.2859 6.75685 9.99276 7.22085 8.52397 7.22085C7.05518 7.22085 5.7632 6.75685 5.5354 6.11099C5.20617 7.01746 4.85144 7.96756 4.54771 8.8151C4.62307 9.72327 6.39616 10.4298 8.49847 10.4298ZM8.49847 4.67877C9.48616 4.67877 10.4251 4.37623 10.6767 3.91167C10.2971 2.90265 9.99275 2.03471 9.79046 1.46986C9.66409 1.10671 9.0538 0.90332 8.49847 0.90332C7.94314 0.90332 7.33342 1.08518 7.20648 1.46986C7.00362 2.01488 6.67439 2.88226 6.32022 3.91167C6.57352 4.37567 7.50965 4.67877 8.49847 4.67877ZM16.2742 12.0438L12.9564 10.5929L13.3361 11.6223C13.3106 12.7118 11.1074 13.6001 8.49847 13.6001C5.88956 13.6001 3.68582 12.7322 3.66088 11.6223L4.04055 10.5929L0.722744 12.0461C-0.214514 12.4093 -0.24058 13.0755 0.646812 13.5395L6.90275 17.5552C7.40793 17.7846 7.95636 17.9033 8.51122 17.9033C9.06608 17.9033 9.61451 17.7846 10.1197 17.5552L16.3756 13.5389C17.2364 13.0936 17.2115 12.4053 16.2742 12.0438Z"
        />
    </svg>
</template>
