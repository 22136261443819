<template>
    <svg
        xmlns:x="http://ns.adobe.com/Extensibility/1.0/"
        xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
        xmlns:graph="http://ns.adobe.com/Graphs/1.0/"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 88 33.9"
        style="enable-background: new 0 0 88 33.9"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    fill="#303030"
                    d="M6,13.8v-2.3l0.8,0V5.3L6,5.4V3.1h3.8v4.2h1.7V3.1h3.8v2.3l-0.8,0v6.2l0.8,0v2.3h-3.8V9.5H9.9v4.3H6z"
                />
                <path
                    fill="#303030"
                    d="M19.1,3.1h2.7c0.9,0,1.6,0.3,2,0.8c0.3,0.4,0.5,1,0.5,1.9v5.5c0,0.9-0.2,1.5-0.5,1.9c-0.5,0.5-1.1,0.8-2,0.8    h-2.7c-0.9,0-1.6-0.3-2-0.8c-0.3-0.4-0.5-1-0.5-1.9V5.7c0-0.9,0.2-1.5,0.5-1.9C17.5,3.3,18.2,3.1,19.1,3.1z M21.3,11.6V5.3h-1.7    v6.2L21.3,11.6z"
                />
                <path
                    fill="#303030"
                    d="M25.6,13.8v-2.3l0.8,0V5.3l-0.8,0V3.1h4.6v2.3l-0.8,0v6.2h1.4V9.7h2.9v4.1H25.6z"
                />
                <path
                    fill="#303030"
                    d="M34.3,13.8v-2.3l0.8,0V5.3l-0.8,0V3.1h6.2c0.9,0,1.6,0.2,1.9,0.5C42.8,4,43,4.5,43,5.3v0.9    c0,0.5-0.1,0.9-0.4,1.3c-0.3,0.3-0.8,0.6-1.3,0.7c0.5,0.1,1,0.4,1.3,0.7c0.3,0.4,0.4,0.9,0.4,1.4v1.1c0,0.9-0.2,1.5-0.6,1.9    c-0.4,0.4-1,0.5-1.9,0.5H34.3z M38.1,7.4H40v-2h-1.8V7.4z M38.1,11.6H40V9.3h-1.8V11.6z"
                />
                <path
                    fill="#303030"
                    d="M44.2,13.8v-2.3l0.8,0V5.3l-0.8,0V3.1h6.1c0.9,0,1.5,0.2,1.9,0.5c0.4,0.4,0.6,0.9,0.6,1.7v1.1    c0,0.4-0.1,0.9-0.3,1.3c-0.3,0.4-0.7,0.6-1.2,0.8c0.5,0.1,0.9,0.3,1.2,0.7c0.2,0.3,0.3,0.8,0.3,1.6v0.9l0.8,0v2.3h-2.4    c-0.6,0-0.9-0.1-1.1-0.2c-0.2-0.2-0.3-0.5-0.3-1V9.5H48v4.3H44.2z M48,7.5h1.8V5.3H48V7.5z"
                />
                <path
                    fill="#303030"
                    d="M57.2,3.1h2.7c0.9,0,1.6,0.3,2,0.8c0.3,0.4,0.5,1,0.5,1.9v5.5c0,0.9-0.2,1.5-0.5,1.9c-0.5,0.5-1.1,0.8-2,0.8    h-2.7c-0.9,0-1.6-0.3-2-0.8c-0.3-0.4-0.5-1-0.5-1.9V5.7c0-0.9,0.2-1.5,0.5-1.9C55.6,3.3,56.3,3.1,57.2,3.1z M59.4,11.6V5.3h-1.7    v6.2L59.4,11.6z"
                />
                <path
                    fill="#303030"
                    d="M66.4,3.1h2.7c0.9,0,1.6,0.3,2,0.8c0.3,0.4,0.5,1,0.5,1.9v5.5c0,0.9-0.2,1.5-0.5,1.9c-0.5,0.5-1.1,0.8-2,0.8    h-2.7c-0.9,0-1.6-0.3-2-0.8c-0.3-0.4-0.5-1-0.5-1.9V5.7c0-0.9,0.2-1.5,0.5-1.9C64.8,3.3,65.5,3.1,66.4,3.1z M68.6,11.6V5.3h-1.7    v6.2L68.6,11.6z"
                />
                <path
                    fill="#303030"
                    d="M72.8,13.8v-2.3l0.8,0V5.3l-0.8,0V3.1h3.9v4.7l2.2-4.7h2.9v2.3l-1,0l-1.4,2.4h2v3.8l0.8,0v2.3h-3.8V9.7h-1.6    v4.1H72.8z"
                />
                <path
                    fill="#303030"
                    d="M5.8,29.4l2.6-7.1h3l2.6,7.1h-2.9l-0.3-0.9h-2l-0.2,0.9H5.8z M9.3,26.8h1.1L10.2,26c-0.1-0.4-0.2-0.7-0.2-0.9    c0-0.2-0.1-0.4-0.1-0.6h0c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.5-0.2,0.9L9.3,26.8z"
                />
                <path
                    fill="#303030"
                    d="M14.2,28.8l0.8-2c0.3,0.2,0.7,0.4,1,0.6c0.3,0.1,0.5,0.2,0.8,0.2c0.2,0,0.3,0,0.4-0.1c0,0,0.1-0.1,0.1-0.1    c0,0,0-0.1,0-0.2c0-0.2-0.3-0.3-0.8-0.4l-0.4-0.1c-0.5-0.1-0.9-0.3-1.3-0.7c-0.3-0.3-0.5-0.8-0.4-1.2c0-0.3,0.1-0.7,0.2-1    c0.1-0.3,0.4-0.6,0.6-0.8c0.6-0.5,1.4-0.8,2.2-0.7c0.4,0,0.8,0.1,1.3,0.2c0.4,0.1,0.8,0.3,1.2,0.5l-0.8,1.9    c-0.2-0.2-0.5-0.3-0.8-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1,0-0.3,0-0.4,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1    c0,0.2,0.4,0.4,1.1,0.5l0.3,0.1c0.5,0.1,0.9,0.3,1.2,0.7c0.3,0.3,0.5,0.8,0.4,1.2c0,0.4-0.1,0.7-0.2,1.1c-0.2,0.3-0.4,0.6-0.7,0.9    c-0.6,0.5-1.5,0.8-2.3,0.7c-0.5,0-1-0.1-1.5-0.2C15.2,29.2,14.7,29,14.2,28.8z"
                />
                <path
                    fill="#303030"
                    d="M21.8,29.4v-7.1H25c1.1,0,1.9,0.2,2.5,0.6s0.8,1.1,0.8,2c0,0.8-0.2,1.4-0.7,1.8c-0.5,0.4-1.2,0.6-2.2,0.6    h-0.8v2.1L21.8,29.4z M24.5,24.3v1.2h0.4c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2    c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.1-0.4-0.2-0.6-0.2H24.5z"
                />
                <path
                    fill="#303030"
                    d="M29.7,29.4v-7.1h2.7v2.3H34v-2.3h2.7v7.1H34v-2.5h-1.6v2.5H29.7z"
                />
                <path
                    fill="#303030"
                    d="M37.6,29.4l2.6-7.1h3l2.6,7.1h-2.9l-0.2-0.9h-2l-0.2,0.9H37.6z M41.1,26.8h1.1L42,26    c-0.1-0.4-0.2-0.7-0.2-0.9c0-0.2-0.1-0.4-0.1-0.6h0c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.5-0.2,0.9L41.1,26.8z"
                />
                <path
                    fill="#303030"
                    d="M46.8,29.4v-7.1h2.7v4.8H52v2.3L46.8,29.4z"
                />
                <path
                    fill="#303030"
                    d="M54,29.4v-4.8h-1.5v-2.3h5.6v2.3h-1.5v4.8H54z"
                />
                <path
                    fill="#303030"
                    d="M69.1,25.1c-0.2-0.2-0.5-0.4-0.7-0.5c-0.3-0.1-0.5-0.2-0.8-0.2c-0.2,0-0.4,0-0.6,0.1    c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.5    c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.1c0.3,0,0.5-0.1,0.8-0.2c0.3-0.1,0.6-0.3,0.9-0.5L69,29.2    c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.4,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.6-0.3-1.1-0.8-1.5-1.4    c-0.4-0.6-0.5-1.2-0.5-1.9c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.5-0.9,0.8-1.2c0.4-0.3,0.8-0.6,1.3-0.8c0.5-0.2,1-0.3,1.6-0.3    c0.6,0,1.2,0.1,1.7,0.4L69.1,25.1z"
                />
                <path
                    fill="#303030"
                    d="M78.5,25.9c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.3,0.8c-1,0.4-2.1,0.4-3.1,0    c-0.5-0.2-0.9-0.5-1.3-0.8c-0.4-0.3-0.6-0.7-0.8-1.2c-0.4-0.9-0.4-2,0-2.9c0.2-0.4,0.5-0.8,0.8-1.2c0.4-0.3,0.8-0.6,1.3-0.8    c1-0.4,2.1-0.4,3.1,0c0.5,0.2,0.9,0.5,1.3,0.8c0.4,0.3,0.6,0.7,0.8,1.2C78.4,24.9,78.5,25.4,78.5,25.9z M74.5,27.2    c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.2-0.3,0.4-0.6,0.3-1c0-0.4-0.1-0.7-0.3-1c-0.1-0.1-0.3-0.2-0.4-0.3    c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0.3-0.4,0.6-0.3,1c0,0.4,0.1,0.7,0.3,1    c0.1,0.1,0.3,0.2,0.4,0.3C74.2,27.2,74.3,27.2,74.5,27.2z"
                />
                <path
                    fill="#303030"
                    d="M79.3,28.4c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.2,0.7-0.3,1-0.3c0.4,0,0.7,0.1,1,0.3    c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.2-0.7,0.3-1,0.3    c-0.4,0-0.7-0.1-1-0.3c-0.1-0.1-0.2-0.2-0.3-0.4C79.4,28.7,79.3,28.5,79.3,28.4z"
                />
                <path fill="#FFC517" d="M19.3,17.2H6v1.3h13.2V17.2z" />
                <path fill="#FFC517" d="M34.9,17.2H21.7v1.3h13.2V17.2z" />
                <path fill="#FFC517" d="M66.3,17.2H53.1v1.3h13.2V17.2z" />
                <path fill="#FFC517" d="M50.6,17.2H37.4v1.3h13.2V17.2z" />
                <path fill="#FFC517" d="M82.1,17.2H68.8v1.3h13.2V17.2z" />
            </g>
        </g>
    </svg>
</template>
