<script setup>
import { useClockStore } from "@/stores/ClockStore";
import dayjs from "dayjs";
import { toast } from "vue3-toastify";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { computed, onMounted, ref } from "vue";

const clock = useClockStore();
const correctedInPunch = ref(null);
const correctedOutPunch = ref(null);

const getMaxInTime = computed(() => {
    // if the out is not on the same day as the in, no min no max
    return {
        hours: dayjs(clock.out).hour(),
        minutes: dayjs(clock.out).minute(),
    };
});

const getMaxTime = computed(() => {
    return {
        hours: dayjs().hour(),
        minutes: dayjs().minute(),
    };
});

const clearInput = (clockType) => {
    if (clockType === "out") {
        // clear clock
        correctedOutPunch.value = null;
        clock.incorrectEntry.out = "";
    }
};

const setInDate = (time) => {
    if (time) {
        clock.incorrectEntry.in = dayjs()
            .set("hour", time.hours)
            .set("minute", time.minutes)
            .set("second", time.seconds)
            .toISOString();
    }
};

const setOutDate = (time) => {
    if (time) {
        clock.incorrectEntry.out = dayjs()
            .set("hour", time.hours)
            .set("minute", time.minutes)
            .set("second", time.seconds)
            .toISOString();
    }
};

function validateTime(date) {
    if (dayjs(date).isBefore(dayjs(clock.incorrectEntry.in))) {
        toast.error("Invalid Out Time.");
    }
}

const shouldOutBeDisabled = computed(() => {
    return !dayjs(clock.incorrectEntry.in).isToday();
});

onMounted(() => {
    // set the in and out time
    correctedInPunch.value = {
        hours: dayjs(clock.incorrectEntry.in).hour(),
        minutes: dayjs(clock.incorrectEntry.in).minute(),
    };
    correctedOutPunch.value = {
        hours: dayjs().hour(),
        minutes: dayjs().minute(),
    };
});
</script>

<template>
<div>
    <div
        class="grid lg:grid-cols-2 grid-cols-1 gap-2 rounded border border-gray-900 px-1 py-2 shadow-md"
    >
        <div class="col-span-2 justify-self-center">
            <span class="text-xs uppercase font-light">
                Update Time Entry
            </span>
            <div class="text-center">
                <span>
                    {{
                        dayjs(clock.incorrectEntry.out)
                            .diff(
                                dayjs(clock.incorrectEntry.in),
                                "hours",
                                true
                            )
                            .toFixed(2)
                    }}
                </span>
                <span class="pl-1 text-xs uppercase font-light">hours</span>
            </div>
        </div>
        <div class="col-span-2 lg:col-span-1">
            <label
                class="block text-sm font-light uppercase leading-6 text-gray-900"
                for="in"
            >Updated In
            </label>
            <VueDatePicker
                v-model="correctedInPunch"
                :clearable="false"
                :is-24="false"
                :max-time="getMaxInTime"
                auto-apply
                class="disable-dbl-tap-zoom"
                input-class-name="dp-single-time-select"
                minutes-grid-increment="1"
                mode-height="150"
                no-today
                placeholder="Select In Time"
                time-picker
                @update:model-value="setInDate"
                @date-update="validateTime"
            ></VueDatePicker>
        </div>
        <div class="col-span-2 lg:col-span-1">
            <label
                class="block text-sm font-light uppercase leading-6 text-gray-900"
                for="out"
            >Updated Out
            </label>
            <VueDatePicker
                v-model="correctedOutPunch"
                :disabled="shouldOutBeDisabled"
                :is-24="false"
                :max-time="getMaxTime"
                :min-time="correctedInPunch"
                auto-apply
                class="disable-dbl-tap-zoom"
                minutes-grid-increment="1"
                mode-height="150"
                no-today
                placeholder="Select Out Time"
                time-picker
                @cleared="clearInput('out')"
                @open="setOutDate"
                @update:model-value="setOutDate"
                @date-update="validateTime"
            ></VueDatePicker>
        </div>
        <div class="col-span-2 border-t">
            <label
                class="block text-sm font-light uppercase leading-6 text-gray-900"
                for="comment"
            >
                Reason
            </label>
            <div class="mt-0">
                <textarea
                    id="comment"
                    v-model="clock.incorrectEntry.reason"
                    :class="[
                        clock.incorrectEntry.reason.length < 3
                            ? 'border-red-500'
                            : '',
                    ]"
                    :disabled="clock.showClockLoading"
                    :placeholder="
                        shouldOutBeDisabled
                            ? 'Entry spans multiple days. Out Select disabled. Please explain...'
                            : 'Enter a reason for the entry change...'
                    "
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-ha-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ha-300 sm:text-sm sm:leading-6"
                    name="comment"
                    rows="4"
                />
                <p
                    v-show="clock.incorrectEntry.reason.length < 3"
                    class="text-red-500 text-xs uppercase"
                >
                    Reason is Required.
                </p>
            </div>
            <span
                v-if="!clock.incorrectEntry.out"
                class="text-xs font-light"
            >
                *If no out provided, current time will be used.
            </span>
        </div>
    </div>
    <div
        class="mt-5 md:mt-6 grid grid-flow-row-dense grid-cols-2 gap-x-2 gap-x-2"
    >
        <button
            :disabled="clock.showClockLoading"
            class="w-full justify-center rounded-md bg-white px-3 py-3 text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-gray-400 disabled:text-white"
            @click="clock.incorrectEntry.denied = false"
        >
            Back
        </button>
        <button
            :disabled="
                clock.isReasonTooShort ||
                    clock.isEditInvalid ||
                    clock.showClockLoading
            "
            class="w-full flex justify-center rounded-md bg-green-600 disabled:bg-gray-400 px-3 py-3 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            type="button"
            @click="clock.updateTimeEntry()"
        >
            <span v-if="clock.showClockLoading" class="flex items-center">
                <span class="pr-2">Saving</span>
                <span
                    class="border-white-300 h-4 w-4 animate-spin rounded-full border-2 border-t-ha-300"
                >
                    <!-- ... -->
                </span>
            </span>
            <span v-else>Correct</span>
        </button>
    </div>
</div>
</template>

<style lang="scss" scoped></style>
