import { computed, ref } from 'vue';

const crumbs = ref([]);

/** @param type item {
    {
        title: string,
        link: string,
        icon: Component | Node | HTMLElement
        isService: Boolean
    },
}
**/
export const setCrumbItems = (items) => {
    crumbs.value = items;
};

export default function useCrumbs() {
    const setCrumbItems = (items) => {
        crumbs.value = items;
    };
    const addToCrumbItems = (item) => {
        const itemTypeIsObject = () =>
            item instanceof Object && !(item instanceof Array);
        if (isArrOfObjects.value && itemTypeIsObject) {
            crumbs.value.push(item);
        }
    };
    const isArrOfObjects = computed(() => {
        return (
            crumbs.value[0] instanceof Object &&
            !(crumbs.value[0] instanceof Array)
        );
    });
    return { crumbs, setCrumbItems, isArrOfObjects, addToCrumbItems };
}
