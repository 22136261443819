<script setup>
import Modal from "./Modal.vue";

const emit = defineEmits(["close"]);

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    contentClass: {
        type: String,
        default: '',
    },
    alignmentClass: {
        type: String,
        default: '',
    },
    maxWidth: {
        type: String,
        default: "2xl",
    },
    closeable: {
        type: Boolean,
        default: true,
    },
    showFooter: {
        type: Boolean,
        default: true,
    },
});

const close = () => {
    emit("close");
};
</script>

<template>
    <Modal
        :show="show"
        :max-width="maxWidth"
        :closeable="closeable"
        :alignment-class="alignmentClass"
        @close="close"
    >
        <div :class="[contentClass]" class="px-6 py-4">
            <slot name='replace'>

            </slot>

            <div class="text-lg font-medium text-gray-900 dark:text-gray-100">
                <slot name="title" />
            </div>

            <div>
                <slot name="content" />
            </div>
        </div>

        <div
            v-if="showFooter"
            class="flex flex-row justify-end px-6 py-4 bg-gray-100 dark:bg-gray-800 text-right"
        >
            <slot name="footer" />
        </div>
    </Modal>
</template>
