<template>
    <svg
        width="21"
        height="16"
        viewBox="0 0 21 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_23490_46576)">
            <path
                d="M19.2498 0H1.7502C1.28641 0.00128687 0.841973 0.189023 0.514021 0.52218C0.186069 0.855338 0.00126677 1.30683 0 1.77798V14.222C0.00126677 14.6932 0.186069 15.1447 0.514021 15.4778C0.841973 15.811 1.28641 15.9987 1.7502 16H19.2498C19.7124 15.9979 20.1553 15.8096 20.4815 15.4763C20.8076 15.143 20.9904 14.692 20.9898 14.222L21 1.77798C20.9987 1.30683 20.8139 0.855338 20.486 0.52218C20.158 0.189023 19.7136 0.00128687 19.2498 0ZM19.2498 14.222H1.7502V1.77798H19.2498V14.222ZM16.6338 13.3242L18.3738 11.5566L17.0538 9.77859H15.6186C15.2103 8.62976 15.2103 7.37207 15.6186 6.22324H17.0538L18.3738 4.44526L16.6338 2.67764C15.4728 3.55845 14.6326 4.80602 14.2452 6.22446C13.9186 7.38635 13.9186 8.61792 14.2452 9.77981C14.6353 11.1963 15.475 12.4423 16.6338 13.3242ZM7.8738 8C8.39303 8.00024 8.90066 7.84405 9.3325 7.55118C9.76434 7.25831 10.101 6.84192 10.2998 6.35467C10.4987 5.86742 10.5509 5.3312 10.4497 4.81384C10.3486 4.29648 10.0986 3.82121 9.73158 3.44815C9.36452 3.07508 8.89679 2.82099 8.38755 2.718C7.87832 2.615 7.35046 2.66774 6.87073 2.86954C6.39101 3.07134 5.98097 3.41313 5.69248 3.85169C5.40398 4.29024 5.25 4.80586 5.25 5.33333C5.25206 6.03993 5.52928 6.717 6.02112 7.21664C6.51296 7.71629 7.17944 7.99791 7.875 8H7.8738ZM9.2538 6.22202C9.7338 6.22202 8.646 3.008 8.646 3.49684L7.257 4.77684L7.8768 5.94651L8.646 4.44221V5.33333C8.6442 4.8445 8.7708 6.22202 9.252 6.22202H9.2538ZM13.1268 12.0795C13.1268 9.85722 9.6528 8.89722 7.8768 8.89722C6.1008 8.89722 2.6268 9.85722 2.6268 12.0795V13.3327H13.1268V12.0795ZM5.9802 11.1043C6.6276 10.66 6.4296 12.5062 7.5666 12.5062C7.9692 12.5062 8.1066 11.9302 8.1066 11.9302C8.1066 11.9302 7.8414 11.2597 8.265 11.5547L8.646 11.1031L6.5064 9.77676L5.9802 11.1043Z"
                fill="curretnColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_23490_46576">
                <rect width="21" height="16" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
</template>
