import { onBeforeMount, ref, watch, nextTick } from "vue";
import { router } from "@inertiajs/vue3";
import { useEventBus } from "@vueuse/core";

const bus = useEventBus('setMainTab');

export function useTabHistory(tabs, defaultSelectedTabKey, queryName = 'tab') {
    if (tabs === undefined || tabs === null) {
        throw new Error('(useTabHistory) Tabs property is required.');
    }

    const selectedKey = ref();
    const selectedIndex = ref();

    onBeforeMount(() => {
        setSelectedTab();
    });

    watch(tabs, async () => {
        await nextTick();

        setSelectedTab();
    });

    function setSelectedTab(tabKey = null, shouldFindDefault = true) {
        const currentTabKey = tabKey ?? paramsFromUrl(queryName);
        const index = tabs.value.findIndex((tab) => getTabKey(tab) === currentTabKey);

        selectedKey.value = currentTabKey;
        selectedIndex.value = index > -1 ? index : 0;

        if (defaultSelectedTabKey.value && shouldFindDefault) {
            setSelectedTab(defaultSelectedTabKey.value, false);
        }
    }

    return {
        selectedKey,
        selectedIndex,
        setSelectedTab,
        pushToHistory,
        getTabKey,
        getTabName,
    };
}

function sanitize(value) {
    return value.toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 \-_]/g, '')
        .replace(/\s+/g, '_');
}

export function getTabName(tab) {
    return tab?.name ?? tab?.title ?? tab;
}

export function getTabKey(tab) {
    return tab?.key ?? sanitize(getTabName(tab));
}

export function pushToHistory(tab, queryName = 'tab', raw = false) {
    const params = paramsFromUrl();
    const value = !raw ? getTabKey(tab) : tab;

    params.set(queryName, value);

    const path = `${window.location.pathname}?${params.toString()}`;
    const routerPage = router.page;
    routerPage.url = path;

    router.setPage(routerPage, {
        replace: false,
        preserveScroll: true,
        preserveState: true
    });
}

export function paramsFromUrl(queryName = null) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (queryName !== null) {
        const value = params.get(queryName);

        return value && !isNaN(value) ? parseInt(value) : value;
    }

    return params;
}

export function goToTab(tab) {
    bus.emit(getTabKey(tab));
}
