import { defineStore } from "pinia";
import _ from "lodash";
import axios from "axios";
import dayjs from "dayjs";

const defaultClockState = {
    clockData: [],
    clockDirection: "In",
    clockType: "choice",
    clockOut: false,
    timeClockTypes: [],
    showClockModal: false,
    showIncorrect: false,
    workOrder: null,
    switchWorkOrder: null,
    timeClock: null,
    showClockLoading: false,
    incorrectEntry: {
        in: "",
        out: "",
        reason: "",
        denied: false,
    },
    mob: {
        from: null,
        to: null,
        method: null,
        fleet_id: null,
    },
};

const user = null;

export const useClockStore = defineStore("clock", {
    state: () => ({ ...defaultClockState, ...user }),
    getters: {
        getTimeClockTypes: (state) => {
            return state.timeClockTypes;
        },
        isUserClockedIn: (state) => {
            return state.user ? state.user.is_clocked_in : false;
        },
        isEditInvalid: (state) => {
            return dayjs(state.incorrectEntry.out).isBefore(
                dayjs(state.incorrectEntry.in)
            );
        },
        isReasonTooShort: (state) => {
            return state.incorrectEntry.reason.length < 3;
        },
        isWorkOrderCompleted: (state) => {
            return !!(state.workOrder && state.workOrder.labor_completed_by);
        },
    },
    actions: {
        resetClock(keys) {
            Object.assign(
                this,
                keys?.length
                    ? _.pick(defaultClockState, keys)
                    : defaultClockState // if no keys provided, reset all
            );
        },
        async getAuthUserData() {
            await axios.get("/auth").then((response) => {
                this.user = response.data;
                this.timeClock = response.data.clocked_entry;
            });
        },
        closeClock() {
            this.showClockModal = false;

            setTimeout(
                () =>
                    this.resetClock([
                        "clockData",
                        "clockDirection",
                        "clockType",
                        "clockOut",
                        "timeClockTypes",
                        "showClockModal",
                        "showIncorrect",
                        "workOrder",
                        "switchWorkOrder",
                        "showClockLoading",
                        "incorrectEntry",
                        "mob",
                    ]),
                1000
            );
            defaultClockState.incorrectEntry = {
                denied: false,
                in: "",
                out: "",
                reason: "",
            };
        },
        toggleClock(clockType, options = {}) {
            this.clockType = clockType;
            this.switchWorkOrder = options.id;
            if (
                this.isUserClockedIn &&
                options.id &&
                options.id !== this.user.clocked_entry.task_id
            ) {
                this.toggleSwitchDialog(
                    this.user.clocked_entry,
                    options.id,
                    clockType
                );
            } else if (this.isUserClockedIn) {
                this.toggleClockOut(this.user.clocked_entry);
            } else {
                // TEMPORARY FIX
                if (
                    (clockType === "task" ||
                        clockType === "cones" ||
                        clockType === "cleaning") &&
                    options.id &&
                    typeof options.id === "number"
                ) {
                    this.switchWorkOrder = options.id;
                    this.fetchWorkOrder(options.id);
                }
                // else if (
                //     clockType === 'cleaning' &&
                //     options.id &&
                //     typeof options.id === 'number'
                // ) {
                //     this.fetchCleaning(options.id);
                // }
                else if (clockType === "choice" || clockType === "mob") {
                    this.showClockModal = true;
                } else {
                    this.saveTimeEntry(clockType);
                }
            }
        },
        toggleSwitchDialog(timeClock, switchWO, clockType) {
            this.timeClock = timeClock;
            this.fetchWorkOrder(switchWO);
            this.clockDirection = "Switch";
            this.clockType = clockType;
            this.showClockModal = true;
        },
        toggleClockOut(timeClock) {
            this.timeClock = timeClock;
            if (timeClock.task) {
                this.workOrder = timeClock.task;
            }
            this.clockDirection = "Out";
            this.clockType = timeClock.type.name;
            this.showClockModal = true;
        },
        toggleEditClock() {
            this.incorrectEntry.in = this.timeClock.in;
            this.incorrectEntry.out = dayjs().toISOString();
            this.incorrectEntry.denied = !this.incorrectEntry.denied;
        },
        handleClockOut(clockType) {
            if (clockType === "Out") {
                this.clockDirection = "Out";
                this.clockOut = true;
            } else {
                this.clockOut = true;
            }
        },
        async fetchTimeClockTypes() {
            try {
                const data = await axios.get("/api/time-clock-types");
                this.timeClockTypes = data.data;
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async fetchCleaning(id) {
            try {
                await axios
                    .get(`/api/projects/cleaning/show/${id}`)
                    .then((response) => {
                        this.clockData = response.data;
                    })
                    .finally(() => {
                        this.showClockModal = true;
                    });
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async fetchWorkOrder(id) {
            try {
                await axios
                    .get(`/api/work-orders/${id}`)
                    .then((response) => {
                        this.workOrder = response.data;
                    })
                    .finally(() => {
                        this.showClockModal = true;
                    });
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async saveTimeEntry(timeCardType, taskId = null) {
            this.showClockLoading = true;
            // handles the saving of the time entry by passing props to controller
            await axios
                .post("/time-clock", {
                    timeclock_type_id: timeCardType,
                    task_id: taskId,
                    mob: this.mob,
                })
                .then(() => {
                    this.resetClock([]);
                    defaultClockState.incorrectEntry = {
                        denied: false,
                        in: "",
                        out: "",
                        reason: "",
                    };
                    this.getAuthUserData();
                });
        },
        async updateTimeEntry() {
            this.showClockLoading = true;

            // Validate time entry
            if (!this.timeClock.in && !this.timeClock.out) {
                return;
            }
            await axios
                .put(`/time-clock/${this.timeClock.id}`, {
                    timeClock: this.timeClock,
                    logData: this.incorrectEntry,
                })
                .then(() => {
                    if (this.clockDirection === "Switch") {
                        // clock into switch
                        this.saveTimeEntry(
                            this.clockType,
                            this.switchWorkOrder
                        );
                    }
                    this.resetClock([]);
                    defaultClockState.incorrectEntry = {
                        denied: false,
                        in: "",
                        out: "",
                        reason: "",
                    };
                    this.getAuthUserData();
                });
        },
    },
});
