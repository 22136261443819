<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 20"
       fill="currentColor"
       stroke="currentColor"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-114.904 -72.435)">
      <g id="Group_55" data-name="Group 55" transform="translate(114.904 72.872)">
        <g id="Group_56" data-name="Group 56" transform="translate(0 0)">
          <g id="Rectangle_72" data-name="Rectangle 72" transform="translate(12 -0.437)" stroke-width="0.2">
            <rect width="14" height="4" stroke="none"/>
            <rect x="0.1" y="0.1" width="13.8" height="3.8" fill="none"/>
          </g>
          <g id="Rectangle_73" data-name="Rectangle 73" transform="translate(0 7.563)" stroke-width="0.2">
            <rect width="26" height="4" stroke="none"/>
            <rect x="0.1" y="0.1" width="25.8" height="3.8" fill="none"/>
          </g>
          <g id="Rectangle_74" data-name="Rectangle 74" transform="translate(0 15.563)" stroke-width="0.2">
            <rect width="14" height="4" stroke="none"/>
            <rect x="0.1" y="0.1" width="13.8" height="3.8" fill="none"/>
          </g>
        </g>
      </g>
    </g>
  </svg>

</template>