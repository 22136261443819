<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.3 21.272">
        <g id="Group_87" data-name="Group 87" transform="translate(-1571 -198.871)">
            <g id="Group_86" data-name="Group 86" transform="translate(1571 198.871)">
                <g id="Group_84" data-name="Group 84" transform="translate(2.9)">
                    <path id="Icon_awesome-warehouse" data-name="Icon awesome-warehouse" d="M19.267,13.236H5.214a.3.3,0,0,0-.306.3l0,1.8a.3.3,0,0,0,.306.3H19.267a.3.3,0,0,0,.306-.3v-1.8A.3.3,0,0,0,19.267,13.236Zm0,3.61H5.2a.3.3,0,0,0-.306.3l0,1.8a.3.3,0,0,0,.306.3H19.267a.3.3,0,0,0,.306-.3v-1.8A.3.3,0,0,0,19.267,16.846Zm0-7.219H5.222a.3.3,0,0,0-.306.3l0,1.8a.3.3,0,0,0,.306.3H19.267a.3.3,0,0,0,.306-.3v-1.8A.3.3,0,0,0,19.267,9.627ZM23.339,4.4,12.937.14a1.869,1.869,0,0,0-1.411,0L1.128,4.4A1.809,1.809,0,0,0,0,6.066V18.952a.3.3,0,0,0,.306.3H3.364a.3.3,0,0,0,.306-.3V9.627a1.227,1.227,0,0,1,1.246-1.2H19.55a1.227,1.227,0,0,1,1.246,1.2v9.325a.3.3,0,0,0,.306.3H24.16a.3.3,0,0,0,.306-.3V6.066A1.809,1.809,0,0,0,23.339,4.4Z" transform="translate(0 -0.002)" />
                    <rect id="Rectangle_87" data-name="Rectangle 87" width="16.106" height="10.94" transform="translate(4.394 9.116)" fill="#303030" />
                </g>
                <g id="Group_85" data-name="Group 85" transform="translate(9.652 8.7)">
                    <path id="Icon_metro-lab" data-name="Icon metro-lab" d="M8.431,6.1,6.79,3.368V2.26h.166a.166.166,0,1,0,0-.332H5.3a.166.166,0,0,0,0,.332h.166V3.368L3.82,6.1c-.376.627-.086,1.139.645,1.139H7.786C8.517,7.242,8.807,6.73,8.431,6.1ZM4.719,5.249l1.074-1.79V2.26h.664v1.2l1.074,1.79Z" transform="translate(-3.655 3.506)" />
                    <path id="Icon_metro-lab-2" data-name="Icon metro-lab" d="M8.431,6.1,6.79,3.368V2.26h.166a.166.166,0,1,0,0-.332H5.3a.166.166,0,0,0,0,.332h.166V3.368L3.82,6.1c-.376.627-.086,1.139.645,1.139H7.786C8.517,7.242,8.807,6.73,8.431,6.1ZM4.719,5.249l1.074-1.79V2.26h.664v1.2l1.074,1.79Z" transform="translate(2.144 3.506)" />
                    <path id="Icon_metro-lab-3" data-name="Icon metro-lab" d="M8.431,6.1,6.79,3.368V2.26h.166a.166.166,0,1,0,0-.332H5.3a.166.166,0,0,0,0,.332h.166V3.368L3.82,6.1c-.376.627-.086,1.139.645,1.139H7.786C8.517,7.242,8.807,6.73,8.431,6.1ZM4.719,5.249l1.074-1.79V2.26h.664v1.2l1.074,1.79Z" transform="translate(-0.511 -1.928)" />
                </g>
                <rect id="Rectangle_88" data-name="Rectangle 88" width="31.3" height="1.216" transform="translate(0 20.056)" />
            </g>
        </g>
    </svg>
</template>
