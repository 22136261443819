<template>
    <svg
        viewBox="0 0 17 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.66667 8.02832H7.93333V10.4033H5.66667V8.02832ZM9.06901 8.02832H11.3357V10.4033H9.06667L9.06901 8.02832ZM12.4714 8.02832H14.738V10.4033H12.4667L12.4714 8.02832ZM2.26667 15.1533H4.53392V17.5283H2.26667V15.1533ZM5.66901 15.1533H7.93568V17.5283H5.66667L5.66901 15.1533ZM9.07136 15.1533H11.338V17.5283H9.06667L9.07136 15.1533ZM5.66901 11.5884H7.93568V13.9658H5.66667L5.66901 11.5884ZM9.07136 11.5884H11.338V13.9658H9.06667L9.07136 11.5884ZM12.4737 11.5884H14.7404V13.9658H12.4667L12.4737 11.5884ZM2.27371 11.5884H4.53392V13.9658H2.26667L2.27371 11.5884ZM14.7333 0.90332V2.09082H12.4667V0.90332H4.53392V2.09082H2.26667V0.90332H0V19.9033H17V0.90332H14.7333ZM15.8667 18.7158H1.13157V5.65332H15.8667V18.7158Z"
        />
    </svg>
</template>
