<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    title: String,
    collapse: Boolean,
    slug: {
        type: [String, Boolean],
        default: false
    }
});
</script>

<template>
    <Link
        :class="[
            $page.url.startsWith(`/${props.slug}`)
                ? 'text-holbrook-yellow border-l-2 border-holbrook-yellow'
                : 'text-white'
        ]"
        class="flex items-center h-12 max-h-12 min-h-12 pl-3 py-2 pr-4 rounded rounded-l-none hover:border-l-2 hover:border-holbrook-yellow hover:bg-holbrook-hover hover:text-holbrook-yellow"
    >
        <span class="flex justify-center inline-block p-1 mr-3 w-14">
            <slot />
        </span>
        <span class="w-4/5">{{ title }}</span>
    </Link>
</template>

<style scoped></style>
