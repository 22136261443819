<template>
    <svg fill="none" stroke="currentColor" viewBox="0 0 90 90" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
            <g id="user" sketch:type="MSLayerGroup" fill="currentColor">
                <g id="Group" sketch:type="MSShapeGroup">
                    <path d="M45,0 C20.187,0 0,20.187 0,45 C0,69.813 20.187,90 45,90 C69.813,90 90,69.813 90,45 C90,20.187 69.813,0 45,0 L45,0 Z M45,86.537 C22.097,86.537 3.463,67.903 3.463,45 C3.463,22.097 22.097,3.463 45,3.463 C67.903,3.463 86.537,22.097 86.537,45 C86.537,67.903 67.903,86.537 45,86.537 L45,86.537 Z" id="Shape"></path>
                    <path d="M45.003,49.795 C54.599,49.795 61.123,42.012 61.123,32.413 C61.123,22.812 54.599,15.032 45.003,15.032 C35.401,15.032 28.877,22.812 28.877,32.413 C28.877,42.012 35.401,49.795 45.003,49.795 L45.003,49.795 Z" id="Shape"></path>
                    <path d="M58.022,48.654 C54.58,51.745 50.074,53.572 45.002,53.572 C39.928,53.572 35.419,51.745 31.977,48.654 C23.79,53.131 18.138,61.63 17.71,71.513 C24.634,78.637 34.306,83.077 45,83.077 C55.694,83.077 65.366,78.637 72.292,71.514 C71.864,61.63 66.209,53.131 58.022,48.654 L58.022,48.654 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
</template>
