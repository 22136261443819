<script setup>
import { router, usePage } from '@inertiajs/vue3';
import Dropdown from '../Dropdown.vue';
import BreadCrumbs from '../BreadCrumbs.vue';
import GridIcon from '../Icons/GridIcon.vue';
import BellIcon from '../Icons/BellIcon.vue';
import DropdownLink from '../DropdownLink.vue';
import CollapseIcon from '../Icons/CollapseIcon.vue';

import { ref } from 'vue';
import { useAppStore } from '@/stores/AppStore';
import { useClockStore } from '@/stores/ClockStore';
import TimerElement from '@/Components/Clock/TimerElement.vue';

const page = usePage();

const logout = () => {
    router.post('/logout');
};

const app = useAppStore();
const clock = useClockStore();
const shouldOpenSideBarMenu = ref();
</script>

<template>
<div
    :class="`${
        app.getNavbarCollapseState ? 'lg:left-20' : 'lg:left-52 xl:left-60'
    }`"
    class="fixed inset-x-0 top-0 z-50 flex h-16 min-h-16 items-center justify-between gap-12 transition px-4 sm:px-6 lg:z-100 dark:backdrop-blur bg-white dark:bg-gray-900/[var(--bg-opacity-dark)] border-b border-gray-900/10 dark:border-white/10"
>
    <div
        class="absolute inset-x-0 top-full h-px transition bg-zinc-900/7.5 dark:bg-white/7.5"
    ></div>
    <div class="hidden lg:block">
        <BreadCrumbs />
    </div>
    <div class="flex grow items-center gap-5 lg:hidden">
        <button
            aria-label="Toggle navigation"
            class="flex h-6 w-6 items-center justify-center rounded-md transition hover:bg-zinc-900/5 dark:hover:bg-black/5"
            type="button"
            @click="app.handleToggleMobileNav"
        >
            <CollapseIcon class="h-4 w-4 text-ha-300"></CollapseIcon>
        </button>
        <div>
            <TimerElement />
        </div>
    </div>
    <div class="flex items-right space-x-4">
        <div class="hidden lg:flex items-center space-x-6">
            <TimerElement />
        </div>
        <div class="hidden lg:flex cursor-pointer items-center">
            <GridIcon />
        </div>
        <div class="hidden lg:flex cursor-pointer items-center">
            <BellIcon />
        </div>
        <div
            class="hidden md:block md:h-5 md:w-px md:bg-zinc-900/10 md:dark:bg-white/15"
        ></div>

        <div class="flex justify-end items-center space-x-6 pr-3 lg:mr-8">
            <div class="ml-3 relative z-[1000]">
                <Dropdown
                    align="right"
                    width="48"
                >
                    <template #trigger="">
                        <button
                            v-if="
                                $page.props.jetstream.managesProfilePhotos
                            "
                            class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition"
                        >
                            <img
                                :alt="$page.props.auth.user.name"
                                :src="
                                    $page.props.auth.user
                                        .temporary_profile_photo_url
                                "
                                class="h-8 w-8 rounded-full object-cover"
                            />
                        </button>
                        <span
                            v-else
                            class="inline-flex rounded-md"
                        >
                            <button
                                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none focus:bg-gray-50 dark:focus:bg-gray-700 active:bg-gray-50 dark:active:bg-gray-700 transition ease-in-out duration-150"
                                type="button"
                            >
                                {{ $page.props.auth.user.name }}
                                <svg
                                    class="ml-2 -mr-0.5 h-4 w-4"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    viewbox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </button>
                        </span>
                    </template>
                    <template #content="">
                        <!-- Account Management -->
                        <div class="block px-4 py-2 text-xs text-gray-400">
                            Manage Account
                        </div>
                        <DropdownLink :href="route('profile.show')">
                            Profile
                        </DropdownLink>
                        <DropdownLink
                            v-if="$page.props.jetstream.hasApiFeatures"
                            :href="route('api-tokens.index')"
                        >
                            API Tokens
                        </DropdownLink>
                        <div
                            class="border-t border-gray-200 dark:border-gray-600"
                        ></div>
                        <!-- Authentication -->
                        <form @submit.prevent="logout">
                            <DropdownLink as="button">
                                Log Out
                            </DropdownLink>
                        </form>
                    </template>
                </Dropdown>
            </div>
        </div>
    </div>
</div>

<div
    class="pb-2 pt-8 bg-holbrook-lighter-gray -mb-1 sticky top-0 lg:hidden md:px-6"
>
    <BreadCrumbs />
</div>
</template>
